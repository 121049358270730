import React, { Component } from "react";
import moment from "moment";
import { Form, InputGroup, Button, Table, Modal } from "react-bootstrap";
// import Calendar from "@toast-ui/react-calendar";
import { Progress } from "antd";
import "./dashboard.css";
import {
  Webiner_coach1,
  LGspinner,
  filter_close,
  coachmaster,
  careermaster,
  webiner1,
} from "../../../assets/images";
import axios from "../../../config/axios";
import message from "../../../utils/mesaages";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from "react-custom-image-loader.";
import { Link } from "react-router-dom";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { convertUTCToLocal } from "../../../utils/timeZoneConvert";
import Select from "react-select";
import defaultProfile from "../../../assets/images/defaultProfileImg.png";
import ReactPaginate from "react-paginate";
import Calendar from "tui-calendar";

import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebase";
import TrialActivationModal from "../TrialActivationModal";

const timeFormat = "DD-MM-YYYY, hh:mm:ss a";
let dcalender;

export default class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.token = localStorage.getItem("authToken") || localStorage.getItem("corporate_user_token");
    this.userDeta = localStorage.getItem("userdata") || localStorage.getItem("corporate_user_userdata");

    this.state = {
      showPaymentModal :  false,
      per_page: 6,
      loader: false,
      webiners: [],
      current_webinars: [],
      current_session: [],
      month_wise_webinars: [],
      totalItems: 0,
      page: 1,
      archived_webinar: [],
      attendance: {},
      percent: 0,

      // FILTER
      webinar_name: "",
      coach_name: "",
      webinar_date: "",
      show: false,
      all_coach: [],
      coach: {},
      isClicked: false,

      // TIMER
      timerOne: {},
      timerTwo: {},

      offset: moment().local().utcOffset(),

      currentDate: "",
      date: "",

      // Archive
      totalWebiner: "",
      JoinWebinerList: []
    };
  }

  componentDidMount() {
    this.getCurrentWebinars();
    this.getAllUpcomingWebiners(1);
    this.getMonthWiseWebinars(moment().format("YYYY-MM-DD"));
    this.displayCalendar();
    // this.getJoinEndWebinars();
    this.getArchivedWebinars();
    this.getAllCoach();
    this.getAttendance();
    this.savefirebaseKey();
    // this.interval = setInterval(() => {
    //   const timeTillDate = moment().add(7, "days").calendar();
    //   const timeFormat = "MMMM DD YYYY, hh:mm:ss a";
    //   const then = moment(timeTillDate, timeFormat).subtract(8, "days");
    //   const now = moment();
    //   const countdown = moment(then - now);
    //   const days = countdown.format("DD");
    //   const hours = countdown.format("HH");
    //   const minutes = countdown.format("mm");
    //   const seconds = countdown.format("ss");
    //   this.setState({ days, hours, minutes, seconds });
    // }, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.timerOne) {
      clearInterval(this.timerOne);
    }

    if (this.timerTwo) {
      clearInterval(this.timerTwo);
    }
  }

  componentDidUpdate = () => {
    dcalender.clear();
    dcalender.createSchedules(this.state.month_wise_webinars);
  };

  savefirebaseKey = async () => {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      // Generate Token 
      const token = await getToken(messaging, { vapidKey: 'BGnNBnQVeR1r2jA2ZrHbYylENr4hGZVSnIc5BEszGO2iGtRCrJHgkGZQjihSZfkOTNo0JvVT3GKWlMMSFmW7YG4' })
      await axios.post("/webinar/insert-firebase-token", { firebase_token: token, uid: JSON.parse(this.userDeta).uid, token: this.token })
      // Send this token to server (db)
    }
  }

  getAllUpcomingWebiners = (page) => {
    this.setState({ loader: true });

    if (localStorage.getItem("corporate_user_token")) {
      axios.get(`/api/corporate_get_all_webinar?per_page=${this.state.per_page}&page=${page}&webinar_name=${this.state.webinar_name}&coach_name=${this.state.coach_name}&webinar_date=${this.state.webinar_date}&company_id=${JSON.parse(this.userDeta).company_id}&hr_id=${JSON.parse(this.userDeta).referal_id}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
        this.setState({ loader: false });
        if (res.data.success) {
          let { webiners, total, per_page, page } = res.data.data;
          this.setState({
            webiners,
            totalItems: total,
            per_page,
            page: parseInt(page),
          });
        } else {
          message.error(res.data.msg);
        }
      }).catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
    } else {
      let url = `/webinar/get-upcoming-webinars?per_page=${this.state.per_page}&page=${page}&webinar_name=${this.state.webinar_name}&coach_name=${this.state.coach_name}&webinar_date=${this.state.webinar_date}`;

      if(localStorage.getItem("ML_TOKEN")){
        url = `${url}&type=2`;
      }else{
        url = `${url}&type=1`;
      }
      axios.get(`${url}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
        this.setState({ loader: false });
        if (res.data.success) {
          let { webiners, total, per_page, page } = res.data.data;
          this.setState({
            webiners,
            totalItems: total,
            per_page,
            page: parseInt(page),
          });
        } else {
          message.error(res.data.msg);
        }
      }).catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
    }

  };

  getCurrentWebinars = () => {
    if (this.timerOne) {
      clearInterval(this.timerOne);
    }

    if (this.timerTwo) {
      clearInterval(this.timerTwo);
    }

    this.setState({ loader: true });

    let { offset } = this.state;
    let params = "";
    if(localStorage.getItem("ML_TOKEN")){
      params = `?type=2`;
    }else{
      params = `?type=1`;
    }

    axios
      .get(`/webinar/get-current-webinar${params}`, {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then(async (res) => {
        if (res.data.success) {
          let current_webinars = res.data.webiners;
          let current_session = res.data.session;

          for (let i = 0; i < current_webinars.length; i++) {
            let webinar = current_webinars[i];
            let deadline = moment.utc(webinar.date_string).tz("America/New_York");
            let times = this.getTime(deadline);
            let isStart =
              times &&
                times.days <= 0 &&
                times.hours <= 0 &&
                times.minutes <= 0 &&
                times.seconds <= 0
                ? true
                : false;
            if (i === 0) {
              this.timerOne = setInterval(() => {
                this.saveTimer(deadline, i);
              }, 1000);
            } else {
              this.timerTwo = setInterval(() => {
                this.saveTimer(deadline, i, false);
              }, 1000);
            }
            current_webinars[i].isStart = isStart;
          }

          for (let i = 0; i < current_session.length; i++) {
            let session = current_session[i];
            let deadline = moment(session.date_time)
              .add(offset, "m")
              .format(timeFormat);

            let times = this.getTime(deadline);
            let isStart =
              times &&
                times.days <= 0 &&
                times.hours <= 0 &&
                (times.minutes <= 0 && times.minutes >= -15) 
                && times.seconds <= 0
                ? true
                : false;

            this.timerTwo = setInterval(() => {
              this.saveSessionTimer(deadline, i, false);
            }, 1000);

            current_session[i].isStart = isStart;
          }

          this.setState({ current_webinars, current_session });
        }
      })
      .catch((err) => {
        // this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  saveTimer = (deadline, position, isFirst = true) => {
    let { days, hours, minutes, seconds } = this.getTime(deadline);
    let { current_webinars } = this.state;

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      current_webinars[position].isStart = true;
      if (isFirst) {
        if (this.timerOne) {
          clearInterval(this.timerOne);
        }
      } else {
        if (this.timerTwo) {
          clearInterval(this.timerTwo);
        }
      }
      this.setState({ current_webinars });
    }

    if (isFirst) {
      let timerOne = {
        days,
        hours,
        minutes,
        seconds,
      };
      this.setState({ timerOne });
    } else {
      let timerTwo = {
        days,
        hours,
        minutes,
        seconds,
      };
      this.setState({ timerTwo });
    }
  };

  saveSessionTimer = (deadline, position, isFirst = true) => {
    let { days, hours, minutes, seconds } = this.getTime(deadline);
    let { current_session } = this.state;

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      current_session[position].isStart = true;
      if (isFirst) {
        if (this.timerOne) {
          clearInterval(this.timerOne);
        }
      } else {
        if (this.timerTwo) {
          clearInterval(this.timerTwo);
        }
      }
      this.setState({ current_session });
    }

    if (isFirst) {
      let timerOne = {
        days,
        hours,
        minutes,
        seconds,
      };
      this.setState({ timerOne });
    } else {
      let timerTwo = {
        days,
        hours,
        minutes,
        seconds,
      };
      this.setState({ timerTwo });
    }
  };

  getTime = (deadline) => {
    let currentDate = moment.tz("America/New_York");
    const startDate = new Date(currentDate); 
    const endDate = new Date(deadline); 
    // console.log("startDate",startDate,currentDate)
    // console.log("endDate",endDate,deadline)
    const timeDifference = endDate - startDate;
    // console.log("timeDifference",timeDifference)
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  getAllCoach = () => {
    axios
      .get("/webinar/get-all-coaches", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let all_coach = res.data.coaches.map((coach) => {
            return {
              label: coach.name,
              value: coach.name,
            };
          });
          this.setState({ all_coach });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getMonthWiseWebinars = (date) => {
    this.setState({ loader: true });

    if (localStorage.getItem("corporate_user_token")) {
      axios.get(`/api/get-monthwise-webinars?date=${date}&company_id=${JSON.parse(this.userDeta).company_id}&hr_id=${JSON.parse(this.userDeta).referal_id}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
        this.setState({ loader: false });

        if (res.data.success) {
          let { month_wise_webinars } = res.data;
          let month_wise_webinars_schedules = month_wise_webinars.map(
            (value) => {
              let aaa = new Date(value.date_time);
              let now = new Date();

              let calendarId = aaa > now ? "2" : "1";
              return {
                id: value.id,
                calendarId: calendarId,
                category: "time",
                title: value.webinar_topic,
                start: new Date(value.date_time),
                isReadOnly: true,
              };
            }
          );
          this.setState({ month_wise_webinars: month_wise_webinars_schedules });
        }
      }).catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
    } else {
      let url = `/webinar/get-monthwise-webinars?date=${date}`;
      if(localStorage.getItem("ML_TOKEN")){
        url = `${url}&type=2`;
      }else{
          url = `${url}&type=1`;
      }
      axios.get(`${url}`, { headers: { "X-Auth": this.token, }, }).then((res) => {
        this.setState({ loader: false });
  
        if (res.data.success) {
          let { month_wise_webinars } = res.data;
          let month_wise_webinars_schedules = month_wise_webinars.map(
            (value) => {
              let aaa = new Date(value.date_time);
              let now = new Date();
  
              let calendarId = aaa > now ? "2" : "1";
              return {
                id: value.id,
                calendarId: calendarId,
                category: "time",
                title: value.webinar_topic,
                start: new Date(value.date_time),
                isReadOnly: true,
              };
            }
          );
          this.setState({ month_wise_webinars: month_wise_webinars_schedules });
        }
      }).catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
    }

  };

  displayCalendar = () => {
    let div = document.getElementById("calendar");

    dcalender = new Calendar(div, {
      defaultView: "month",
      taskView: true, // Can be also ['milestone', 'task']
      scheduleView: true, // Can be also ['allday', 'time']
      useCreationPopup: false,
      useDetailPopup: true,
      isReadOnly: true,
      calendars: [
        {
          id: "1",
          name: "Archived",
          bgColor: "#9e5fff",
          dragBgColor: "#9e5fff",
          borderColor: "#9e5fff",
        },
        {
          id: "2",
          name: "Upcoming",
          bgColor: "#00e1ba",
          borderColor: "#00e1ba",
          dragBgColor: "#00e1ba",
        },
      ],
    });

    this.setState({
      currentDate: moment(dcalender.getDate()).format("MMMM-DD"),
    });
  };

  moveToNextOrPrevRange = async (val) => {
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    if (val === -1) {
      dcalender.prev();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");

      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getMonthWiseWebinars(qDate);
    } else if (val === 1) {
      dcalender.next();
      let date = dcalender.getDate().toDate();
      const qDate = moment(date).format("YYYY-MM-DD");
      await this.setState({
        currentDate: `${months[date.getMonth()]}-${date.getFullYear()}`,
        date: qDate,
      });
      this.getMonthWiseWebinars(qDate);
    }
  };

  onPageChange = async (page) => {
    let currentPage = page.selected + 1;
    await this.setState({ currentPage });
    this.getAllUpcomingWebiners(currentPage);
  };

  handleChange = async (e) => {
    e.persist();
    let { name, value } = e.target;
    await this.setState({ [name]: value });
  };

  scheduleWebinar = (webinar_id, coach_uid) => {
    
    // if(this.props.isExpired){
    //   this.setState({showPaymentModal : true});
    //   return;
    // }
    
    this.setState({ loader: true });

    axios
      .post(
        "/webinar/scheduled-webinars",
        { webinar_id, coach_uid },
        {
          headers: {
            "X-Auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          message.success(res.data.msg);
          this.getAllUpcomingWebiners(this.state.page);
          this.getCurrentWebinars();
        } else {
          this.setState({ loader: false });

          message.config({
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            style: {
              position: 'fixed',
              transform: 'translate(-50%, -50%)',
              left: '50%',
              top: '50%',
              zIndex: 1000,
            }
          });
          message.error({
            content: res.data.msg,
            duration: 10, // Display the message for 30 seconds
          });

          if(res.data.is_free){
              this.setState({showPaymentModal : true});  
          }
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  handleClose = () => {
    this.setState({
      show: false,
      coach_name: "",
      webinar_date: "",
      coach: {},
    });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  getArchivedWebinars = () => {
    axios
      .get("/webinar/get-webiner-history", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let { completed } = res.data.webinar;
          this.setState({ archived_webinar: completed });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  getJoinEndWebinars = () => {
    axios.post("/webinar/GetJoinWebiner", { userID: JSON.parse(this.userDeta).uid }, { headers: { "X-Auth": this.token, }, }).then((res) => {
      if (res.data.success) {
        this.setState({ totalWebiner: res.data.data.length, JoinWebinerList: res.data.data })
      }
    }).catch((err) => {
      console.log(err);
      message.error("Something Went Wrong");
    })
  }

  clearFilter = async (key) => {
    switch (key) {
      case "coach_name":
        await this.setState({ [key]: "", coach: {} });
        break;

      default:
        await this.setState({ [key]: "" });
        break;
    }
    this.getAllUpcomingWebiners(1);
    let { webinar_name, coach_name, webinar_date } = this.state;
    if (!webinar_name && !coach_name && !webinar_date) {
      this.setState({ isClicked: false });
    }
  };

  clearAllFilter = () => {
    this.setState(
      {
        webinar_name: "",
        coach_name: "",
        webinar_date: "",
        show: false,
        coach: {},
        isClicked: false,
      },
      () => {
        this.getAllUpcomingWebiners(1);
      }
    );
  };

  getAttendance = () => {
    axios
      .get("/webinar/get-webiner-attendace", {
        headers: {
          "X-Auth": this.token,
        },
      })
      .then((res) => {
        if (res.data.success) {
          let { completed, total } = res.data.attendance;
          this.setState({ attendance: res.data.attendance });
          let percent = (this.state.totalWebiner / total) * 100;
          percent = Math.round(percent);
          this.setState({ percent: percent });
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  joinWebinar = (webinar_id) => {
    this.setState({ loader: true });
    axios
      .post(
        `/webinar/join-webinar`,
        {
          webinar_id,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          let url = res.data.bbb_joining_link;
          this.setState({ loader: false });
          window.open(url, "_blank");
        } else {
          this.setState({ loader: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log(err);
        message.error("Something Went Wrong");
      });
  };

  joinSession = (session_id) => {
    this.setState({ loader: true });
    axios
      .post(
        `/webinar/join-session`,
        {
          session_id,
        },
        {
          headers: {
            "x-auth": this.token,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          // console.log("bbb response", res.data);
          this.setState({ loader: false });
          let url = res.data.joiningLink;
          window.open(url, "_blank");
        } else {
          this.setState({ loader: false });
          message.error(res.data.msg);
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        console.log("err", err);
      });
  };

  render() {
    const {
      webiners,
      totalItems,
      per_page,
      page,
      current_webinars,
      timerOne,
      timerTwo,
      archived_webinar,
      attendance,
      percent,
      current_session,
    } = this.state;

    return (
      <LoadingOverlay active={this.state.loader} spinner={<CustomImageLoader image={LGspinner} animationType={"none"} />} className="webiner_loader">
        <div className="content_wrap">
          {current_webinars.map((webinar, key) => {
            return (
              <div key={key}>
                {webinar.isStart ? (
                  <div className="card_style_1 ws_started">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="wb_row">
                            <div className="wb_image">
                              <img src={coachmaster} />
                            </div>
                            <div className="webiner_start">
                              <div className="wb_c">Coach Master</div>
                              <div className="webiner_name">
                                <span className="text-uppercase">
                                  "{webinar.webinar_topic}"
                                </span>{" "}
                                webinar has started
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <button
                            className="defaultBtn rounded"
                            onClick={() => this.joinWebinar(webinar.id)}
                          >
                            Join Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card_style_1">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="wb_row">
                            <div className="wb_image">
                              <img src={coachmaster} />
                            </div>
                            <div className="webiner_start">
                              <div className="wb_c">Coach Master</div>
                              <h4 className="webiner_start_title">
                                Webinar Starts In
                              </h4>
                              <div className="webiner_name">
                                <span className="text-uppercase">
                                  {webinar.webinar_topic}
                                </span>{" "}
                                : {webinar.coach_name}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="countdown_wrap d-flex justify-content-end active">
                            <div className="countdown_number">
                              <span className="count_up">
                                {key === 0 ? timerOne.days : timerTwo.days}
                              </span>
                              <small className="text-uppercase">DAYS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">
                                {key === 0 ? timerOne.hours : timerTwo.hours}
                              </span>
                              <small className="text-uppercase">HRS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">
                                {key === 0
                                  ? timerOne.minutes
                                  : timerTwo.minutes}
                              </span>
                              <small className="text-uppercase">MINS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">
                                {key === 0
                                  ? timerOne.seconds
                                  : timerTwo.seconds}
                              </span>
                              <small className="text-uppercase">SECS</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          {current_session.map((session, key) => {
            return (
              <div key={key}>
                {session.isStart ? (
                  <div className="card_style_1 ws_started">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-6">
                          <div className="wb_row">
                            <div className="wb_image">
                              <img src={careermaster} />
                            </div>
                            <div className="webiner_start">
                              <div className="wb_c">Career Master</div>
                              <div className="webiner_name" style={{"color":"black !important"}}>
                                <span className="text-uppercase">
                                  "{session.session_name}"
                                </span>{" "}
                                session has been started
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          <button
                            className="defaultBtn rounded"
                            onClick={() => this.joinSession(session.id)}
                          >
                            Join Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="card_style_1">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-md-12 col-lg-6">
                          <div className="wb_row">
                            <div className="wb_image">
                              <img src={careermaster} />
                            </div>
                            <div className="webiner_start">
                              <div className="wb_c">Career Master</div>
                              <h4 className="webiner_start_title">
                                Session Starts In
                              </h4>
                              <div className="webiner_name">
                                <span className="text-uppercase">
                                  {session.session_name}
                                </span>{" "}
                                : {session.coach_name}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 col-lg-6">
                          <div className="countdown_wrap d-flex justify-content-end active">
                            <div className="countdown_number">
                              <span className="count_up">{timerTwo.days}</span>
                              <small className="text-uppercase">DAYS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">{timerTwo.hours}</span>
                              <small className="text-uppercase">HRS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">
                                {timerTwo.minutes}
                              </span>
                              <small className="text-uppercase">MINS</small>
                            </div>
                            <div className="countdown_number">
                              <span className="count_up">
                                {timerTwo.seconds}
                              </span>
                              <small className="text-uppercase">SECS</small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}

          {/* <div className="card_style_1">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <div className="webiner_start">
                    <h4 className="webiner_start_title">
                      Next Webinar Start In
                    </h4>
                    <div className="webiner_name">
                      <span className="text-uppercase">Nutrition & health</span>{" "}
                      : Serena Paul
                    </div>
                  </div>
                </div>

                <div className="col-md-12 col-lg-6">
                  <div className="countdown_wrap d-flex justify-content-end active">
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">DAYS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">HRS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">MINS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">SECS</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card_style_1">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-12 col-lg-6">
                  <div className="webiner_start">
                    <div className="webiner_name mb-3">
                      <span className="text-uppercase">
                        “COACHING & THERAPIES”
                      </span>{" "}
                      Webinar has started
                    </div>
                    <a href="/#" className="defaultBtn rounded">
                      Join Now
                    </a>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="countdown_wrap d-flex justify-content-end inactive">
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">DAYS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">HRS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">MINS</small>
                    </div>
                    <div className="countdown_number">
                      <span className="count_up">00</span>
                      <small className="text-uppercase">SECS</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="card_style_1">
            <div className="card-header border-0 d-flex flex-wrap align-items-center justify-content-between">
              <h4 className="card-title">Upcoming Webinars</h4>
              <div className="card-tool d-flex col-md-12 col-lg-6 pr-0">
                <InputGroup className="search_webiner">
                  <Form.Control
                    className="border-0 m-0"
                    placeholder="Type here..."
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={this.handleChange}
                    name="webinar_name"
                    value={this.state.webinar_name}
                  />
                  <InputGroup.Text
                    id="basic-addon1"
                    onClick={() => {
                      this.getAllUpcomingWebiners(1);
                      this.setState({ isClicked: true });
                    }}
                  >
                    <i className="fa fa-search" />
                  </InputGroup.Text>
                </InputGroup>
                <div className="filter_webiner">
                  <Button variant="default" onClick={this.handleShow}>
                    <i className="fa fa-sliders" /> Filter
                  </Button>
                </div>
              </div>
            </div>

            {this.state.isClicked && (
              <div className="apply_filter">
                {this.state.webinar_name && (
                  <span>
                    {this.state.webinar_name}{" "}
                    <img
                      width={15}
                      height={15}
                      onClick={() => {
                        this.clearFilter("webinar_name");
                      }}
                      src={filter_close}
                    />
                  </span>
                )}
                {this.state.coach_name && (
                  <span>
                    {this.state.coach_name}{" "}
                    <img
                      width={15}
                      height={15}
                      onClick={() => {
                        this.clearFilter("coach_name");
                      }}
                      src={filter_close}
                    />
                  </span>
                )}
                {this.state.webinar_date && (
                  <span>
                    {this.state.webinar_date}{" "}
                    <img
                      width={15}
                      height={15}
                      onClick={() => {
                        this.clearFilter("webinar_date");
                      }}
                      src={filter_close}
                    />
                  </span>
                )}

                {(this.state.webinar_name ||
                  this.state.coach_name ||
                  this.state.webinar_date) && (
                    <strong onClick={this.clearAllFilter}>CLEAR FILTER</strong>
                  )}
              </div>
            )}

            <div className="card-body">
              {webiners?.length === 0 && <p>No upcoming webinars currently</p>}
              <div className="row">
                {webiners &&
                  webiners.map((value, key) => {
                    return (
                      <div className="col-md-6 col-sm-6 col-lg-4" key={key}>
                        <div className="webiner_card">
                        <img src={value.image || webiner1} className="img-fluid" alt="webinar"/>
                          <div className="webiner_card_content text-light">
                            <img
                              width={60}
                              height={60}
                              src={value.coach_image || Webiner_coach1}
                              className="img-fluid rounded-circle"
                              alt={value.coach_name}
                            />
                            <h4 className="webiner_coach_name mt-2">
                              {value.coach_name}
                            </h4>
                            <p className="webiner_coach_details">
                              {(value?.coach_bio?.length > 20) ? value?.coach_bio.substring(0, 20)+"..." : value?.coach_bio}
                            </p>
                            <h3 className="webiner_name">
                              {value.webinar_topic}
                            </h3>
                            <p className="webinar_description">
                              {(value?.script?.length > 30) ? value.script?.substring(0, 30)+"..." : value?.script}
                            </p>
                            <div className="text-uppercase">
                              <small style={{ fontSize: ".7rem" }}>
                              {/* {moment.parseZone(value.date_string).local().format("D MMM, YYYY - h:mm A")} */}
                                {/* {moment(value.date_time).format(
                                  "D MMM, YYYY - h:mm A"
                                )} */}
                              {moment.utc(value.date_string).tz("America/New_York").format("D MMM, YYYY - h:mm A")}  <b>EST</b> 
                              </small>
                            </div>

                            <div className="wb_links row">
                              {value.isSchedule ? (
                                <div className="col-6">
                                  <Button 
                                  disabled
                                  variant="secondary" 
                                  className="scheduled">SCHEDULED</Button>
                                </div>
                              ) : (
                                <div className="col-6">
                                  <Button
                                    variant="default"
                                    className="defaultBtn"
                                    onClick={() => {
                                      this.scheduleWebinar(
                                        value.id,
                                        value.coach_uid
                                      );
                                    }}
                                  >SIGN UP</Button>
                                </div>
                              )}

                              <div className="col-6">
                                <Link
                                  to={`/webinars/webinars-details/${value.coach_uid}/${value.id}`}
                                  className="btn defaultBtn btn-bordered"
                                >View More</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            { this.state.showPaymentModal && <TrialActivationModal  close = {()=>{this.setState({showPaymentModal : false})}} show={Math.ceil()} /> }
            <div className="card-footer border-0">
              <div className="row align-items-center">
                <div className="col-md-3">
                  <p className="mb-0">
                    Showing {Math.min(totalItems, page * per_page)} of{" "}
                    {totalItems} Webinars
                  </p>
                </div>
                <div className="col-md-9 result_pagination">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={this.onPageChange}
                    pageRangeDisplayed={3}
                    pageCount={Math.ceil(totalItems / per_page)}
                    previousLabel="<"
                    activeClassName="active"
                    disabledClassName="disable"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="card_style_1">
            <div className="card-header">
              <h4 className="card-title">Webinars Calendar</h4>
            </div>
            {/* <div className="card-body">
              <Calendar
                height="600px"
                view="month"
                schedules={schedules}
                scheduleView
                taskView
                useDetailPopup
                isReadOnly={true}
                calendars={calendars}
              />
            </div> */}

            <div className="booking__calendar">
              <div className="ml-3 pt-3">
                <span id="menu-navi">
                  <button
                    type="button"
                    className="btn btn-default btn-sm move-day"
                    data-action="move-prev"
                    onClick={() => {
                      this.moveToNextOrPrevRange(-1);
                    }}
                  >
                    <i
                      className="calendar-icon ic-arrow-line-left"
                      data-action="move-prev"
                    ></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-default btn-sm move-day"
                    data-action="move-next"
                    onClick={() => {
                      this.moveToNextOrPrevRange(1);
                    }}
                  >
                    <i
                      className="calendar-icon ic-arrow-line-right"
                      data-action="move-next"
                    ></i>
                  </button>
                </span>
                <span id="renderRange" className="render-range">
                  {this.state.currentDate}
                </span>
              </div>

              <div id="calendar" className="mb-3"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-lg-4">
              <div className="card_style_1">
                <div className="card-header border-0">
                  <h4 className="card-title">Webinars Attendance</h4>
                </div>
                <div className="card-body row">
                  <div className="col-md-7 col-lg-7 col-6">
                    <div className="completed_progress">
                      <Progress type="circle" strokeColor={{ "0%": "#00E1BA", "100%": "#00E1BA", }} percent={percent} width={150} trailColor="#E1E1E1" />
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 col-6">
                    <h2 className="completed_title">Sessions Completed</h2>
                    <div className="completed_count">
                      <span>{this.state.totalWebiner}</span> / {attendance.total}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-12 col-lg-8">
              <div className="card_style_1">
                <div className="card-header border-0 d-flex justify-content-between">
                  <h4 className="card-title">Archived Webinars </h4>
                  
                </div>
                <div className="card-body">
                  <Table className="aw_table" responsive>
                    <thead>
                      <tr>
                        <th>Webinar title</th>
                        <th>Coach</th>
                        
                        <th>Session Date /Time </th>
                        <th>Attendance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.JoinWebinerList.map((item) => {
                        return (
                          <tr>
                            <td>{item.webinar_topic}</td>
                            <td>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <img src={item.image === '' ? defaultProfile : item.image} className="rounded-circle px-2" widlth={40} height={40} alt="" /> {item.name}
                              </div>
                            </td>
                            <td>{moment(item.date_time).local(true).format("DD-MM-YYYY/ HH:mm:ss")}</td>
                            <td><strong>{item.is_joined ? (<i className="fa fa-check" aria-hidden="true" style={{ "color": "#00e1ba" }}></i>) : (<i className="fa fa-times" aria-hidden="true" style={{ "color": "#00e1ba" }}></i>)}</strong></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div> */}
          </div>

          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Advance Filter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Coach Name</Form.Label>
                <Select
                  options={this.state.all_coach}
                  onChange={(value) => {
                    this.setState({ coach_name: value.value, coach: value });
                  }}
                  value={this.state.coach}
                />
              </Form.Group>

              <Form.Label>Webinar Date</Form.Label>
              <ReactDatePicker
                selected={
                  this.state.webinar_date !== "" &&
                  new Date(this.state.webinar_date)
                }
                className="starting_datepicker"
                dateFormat="dd-MM-yyyy"
                onChange={(date) => {
                  this.setState({
                    webinar_date: moment(date).format("YYYY-MM-DD"),
                  });
                }}
                placeholderText="Webinar Date"
                minDate={new Date()}
              />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="wb_links defaultBtn rounded"
                onClick={() => {
                  // this.handleClose();
                  this.setState({ show: false, isClicked: true });
                  this.getAllUpcomingWebiners(1);
                }}
              >
                Find
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </LoadingOverlay>
    );
  }
}
