import React,{useState,useEffect}from 'react';
import moment from 'moment';
import { Form, Row, Col, Button,Container } from "react-bootstrap";
import axios from "../../config/axios";
import { useHistory } from 'react-router-dom';
import { message } from "antd";

const JobseekerPaymentHistory = (props) => {
  const [subscription,setSubscription] = useState([])
  const history = useHistory();
  const token = localStorage.getItem("authToken");
  if(token == "" || token == undefined){
    history.push('/webinars');
  }

  useEffect(function(){
    axios
      .post("/webinar/getUserPayments", {}, {
        headers: { "X-Auth": token },
      })
      .then((res) => {
        if (res.data.success) {
          setSubscription(res.data.data);
        } else if (res.data.errors && res.data.errors.length > 0) {
            setSubscription([]);
        } else {
          console.error(res.data.msg);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },[])
  

  const cancelSubscription = ()=>{
    if(window.confirm("Are you sure you want to cancel the subscription ?")){
      let userdata = JSON.parse(localStorage.getItem("userdata"));
      axios
      .post("users/cancel-subscription", { user_id : userdata.id}, {
        headers: {"X-Auth": localStorage.getItem("authToken")},
      })
      .then((res) => {
        if (res.data.success) {
          message.success({
            content: res.data.message,
            duration: 60, // Display the message for 30 seconds
          });

          setTimeout(function(){
            window.location.reload();
          },60000)
          
        }else{
          message.error(res.data.message);
          setTimeout(function(){
            window.location.reload();
          },1500)
        }
      });      
    }
  }

  if (subscription.length == 0) {
    return <Container>
        <Row className='mt-5'>
            <Col md="3"></Col>
            <Col md="6">No payment data available.
            </Col>
            <Col md="3"></Col>
            </Row>
        </Container>;
  }

  return (
    <Container>
        <Row className='mt-5'>
        <Col md="1"></Col>
            <Col md="10">
      <h2>Payment History</h2>
      <table  className="table table-bordered table-responsive">
        <thead>
          <tr>
            <th>Subscription ID</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Currency</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Cancelled At</th>
          </tr>
        </thead>
        <tbody>
        {subscription.map((item,i)=>{
            let d = JSON.parse(item.response)
            if(d){
            return (<tr key={i}>
            <td>{d.id}</td>
            <td>${d.plan?.amount / 100}</td>
            {/* <td>{d.status}</td> */}
            <td>{item.subscription_cancelled_at ? "Cancelled"  : "Active"}</td>
            <td>{d.currency}</td>
            <td>{moment.unix(d.start_date).format('MMM-DD-YYYY')}</td>
            <td>
              {item.subscription_cancelled_at ? "Cancelled" : (
              d.cancel_at_period_end ? moment(item.expiry_date).format('MMM-DD-YYYY') : 'Subscription is active.')}</td>
            <td>{item.subscription_cancelled_at ? moment(item.subscription_cancelled_at).format('MMM-DD-YYYY') : ""}</td>
            {/* expiry_date */}
          </tr>)
          }
        })}
        
          
        </tbody>
      </table>

      { props.user.stripe_customer_id != "" && props.user.stripe_customer_id != null && props.user.subscription_cancelled_at == null && <>
                  <Button
                        className="defaultBtn"
                        variant="default"
                        onClick={cancelSubscription}
                      >Cancel Subscription
                      </Button>
                      <br />
                      <br />
                      <br />
                    <h5>Cancellation and Refund Policy:</h5>
                    <p style={{"text-align":"justify","font-size":"0.9rem"}}>Coach Master offers a free subscription plan. If an upgraded paid subscription plan is selected and commences it will continue on a rolling basis until cancelled. It may be cancelled at any time from the “Payment History” tab. Coach Master does not offer a refund for a partially used month. A cancelled subscription will stop at the end of that billing cycle (one month/or one year) and no further payments taken. Access to Services will remain until the end of that billing cycle.</p>
                    <br />
                    </>
                  }

     
      </Col>

    </Row>
    </Container>
  );
};

export default JobseekerPaymentHistory;