import React, { Component } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { Header, HeaderLogout, HeaderTop } from "../components/headers";
import { FooterLink } from "../components/footers";
import axios from "../config/axios";
import message from '../utils/mesaages';
import '../assets/css/about.css';
import './pricing.css';
import LoadingOverlay from "react-loading-overlay";
import { blog1, dIcon, LGspinner } from '../assets/images'
import CustomImageLoader from 'react-custom-image-loader.'
import pricingbanner from "../assets/images/pricingbanner.jpg"
import HeaderSecondTo from '../components/corporate/Header/HeaderSecondTo';

class Pricing extends Component {
  constructor(props) {
    super(props)
    this.state = {
      plans : [],
      filteredPlans : [],
      filter_type : "monthly",
      about_video: [],
      features: [],
      team: [],
      mission: [],
      loader: false,
    }
  }

  createMarkup = (data) => {
    return { __html: data };
  }

  componentDidMount() {
    this.getPlans()
  }

  getPlans = () => {
    this.setState({ loader: true })
    let self = this
    axios.get('/get-plans')
      .then(async (response) => {
        if (response.status === 200) {
          self.setState({ plans: response.data.data, loader: false,filteredPlans :response.data.data.filter((item)=>item.type == self.state.filter_type) });
        } else {
          this.setState({ loader: false })
          message.error("Error response");
        }
      })
      .catch((e) => {
        message.error("Error while fetching data");
        console.log(e)
        this.setState({ loader: false })
      })
  }

  render() {
    return (

      <>
        {
          !localStorage.getItem("subdomain") && (localStorage.getItem("authToken") == null ? (<HeaderTop />) : (<HeaderLogout />))
        }
        {
          localStorage.getItem("subdomain") ? (<HeaderSecondTo />) : (<Header />)
        }


        <LoadingOverlay active={this.state.loader} spinner={<CustomImageLoader image={LGspinner} animationType={'none'} />} styles={{ wrapper: { overflow: this.state.loader ? 'hidden' : '' } }}>
          <div className="home" style={{ backgroundColor: "white" }}>
            <Banner />
            </div>


            <Container className='mt-3 p-5'>
                    <Row>
                    <Col md={12}>
                    <main id="main">
 
 
 <section className="bsb-pricing-3-pro py-5 py-xl-8">
   <div className="container">
     <div className="row justify-content-md-center">
       <div className="col-12 col-md-10 col-lg-8 col-xl-7">
         {/* <h3 className="fs-5 mb-2 text-secondary text-center text-uppercase">Our Pricing</h3> */}
         <h1 className="display-5 mb-5 text-center">Select your subscription</h1>
         <div className="row justify-content-center" id="price">
           <div className="col-12 col-md-12">
             <div className="alert alert-light mb-5 mb-xl-12 shadow-sm" role="alert">
               <div className="d-flex justify-content-center align-items-center">
                 <h3 className="me-2">Monthly &nbsp;&nbsp;&nbsp; </h3>
                 <label className="switch">
                 <input type="checkbox" onChange={()=>{
                  let f = this.state.filter_type;
                  if(f == 'monthly'){
                    f = "yearly";
                  } else{
                    f = "monthly";
                  }
                  this.setState({ filter_type: f,filteredPlans :this.state.plans.filter((item)=>item.type == f) });
                 }}/>
                <span className="slider round"></span>
              </label>
                <h3>  &nbsp;&nbsp;&nbsp; Yearly</h3>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
 
   <div className="container">
     <div className="row">
       <div className="col-12">
         <div className="container-fluid  pb-4 mt-md-6 ">
           <div className="row">
            
           { 
            this.state.filter_type == "monthly" && 

            <div className={`col-12  col-md-6`}>
              <div className={`card shadow bg-transparent`} >
                <div className="card-body p-4 p-xxl-5">
                  
                  
                  <div className="plan-amount-wrapper position-relative mb-1">
                    <div className="plan-amount bsb-animated">
                      <h4 className="display-3 fw-bold mb-0" style={{"color":"#00e1ba"}}>Free</h4>
                    </div>
                  </div>
                  <p className="h4 mb-4">Free Subscription</p>
                  {/* <p className="text-secondary mb-5"></p> */}
                  <div className="d-grid gap-2 my-5">
                  
                  </div>
                  <ul className="list-group list-group-flush m-0 mt-5">
                        <li className={`list-group-item bg-transparent`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Join 2 live coaching webinar events per month</li>
                        <li className={`list-group-item bg-transparent`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;Access 5 on-demand videos </li>
                        <li className={`list-group-item bg-transparent`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;New live webinar events being scheduled every week </li>
                        <li className={`list-group-item bg-transparent`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Explore inspiring speakers and life-enhancing coaches </li>
                        <li className={`list-group-item bg-transparent`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp; Absolutely zero cost !</li>
                        
                  </ul>
                  <div className="text-center mt-2">
                    <button  style={{"backgroundColor" : "orange","borderColor":"orange"}} onClick={()=>{window.location.href="/user_registration"}} className='btn btn-primary'>Select</button>
                  </div>
                </div>
              </div>
            </div> 
            
           }
            {this.state.filteredPlans.length > 0 ? this.state.filteredPlans.map((item,key)=>{
             return <> <div className={`col-12  col-md-6`} key={key}>
              <div className={`card shadow ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`} >
                <div className="card-body p-4 p-xxl-5">
                  {/* <h2 className="h4 mb-2">{item.plan_name}</h2> */}
                  {/* <div className='amp mt-5'>
                        <h2 style={{"font-size":"1.45rem"}}> 
                            Limited Time Offer <br />
                               <small style={{color : "#000"}}> Sign up now and get 7 days access (no credit card required)</small>
                        </h2>
                    </div>
                  
                  <hr className="my-4 border-dark-subtle" /> */}
                  <div className="plan-amount-wrapper position-relative mb-4">
                    <div className="plan-amount bsb-animated">
                      <h4 className="display-3 fw-bold mb-0" style={{"color":"#00e1ba"}}>Standard</h4>
                      {/* <p className="text-secondary mb-0">USD / {item.time_period === '1' ? this.state.filter_type == "monthly" ? "monthly" : "annually" : this.state.filter_type == "monthly" ? item.time_period+" month" : item.time_period+" annual"}</p> */}
                      <p className="h4 mb-5">{item.plan_name}</p>
                    </div>
                  </div>
                  {/* <p className="text-secondary mb-0">{item.description}</p> */}
                  <div className="d-grid gap-2 my-4">
                  </div>
                  <ul className="list-group list-group-flush m-0 mt-1">
                        <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Join all live coaching webinar events</li>
                        <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;Access all on-demand videos </li>
                        <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;New live webinar events being scheduled every week </li>
                        <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Explore inspiring speakers and life-enhancing coaches </li>
                        <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Cancel your subscription any time </li>                        
                  </ul>
                  <div className="text-center mt-2">
                    <button  style={{"backgroundColor" : "orange","borderColor":"orange"}} onClick={()=>{window.location.href="/user_registration?package="+item.id}} className='btn btn-primary'>Select</button>
                  </div>
                </div>
              </div>
            </div> 
            </>
            }) : `Sorry We do not have any ${this.state.filter_type} Plan as of now`}
            
           </div>
         </div>
       </div>
     </div>
   </div>
 </section>
   </main>
                    </Col>
                </Row>
            </Container>
        </LoadingOverlay>
        <FooterLink />
      </>

    );
  }


}


class Banner extends Component {
  render() {
    return (
      <div className="about_banner">
        <img src={pricingbanner} className="img-fluid" />

        <div className="about_inner_text flex-column">
          <h2>Pricing</h2>
          {/* <span className="R_divider"></span> */}
        </div>
      </div>
    );
  }
}

export default Pricing