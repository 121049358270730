import React,{useEffect,useState} from 'react'
import { Col, Row } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import axios from "../../../config/axios";
import {STRIPE_KEY} from "../../../config/custom";
import LoadingOverlay from "react-loading-overlay";
import CustomImageLoader from 'react-custom-image-loader.'
import { LGspinner } from '../../../assets/images';

export default function PaymentModule(props) {
    const [showLoader,setShowLoader] = useState(false);
    const [plans,setPlans] = useState([]);
    const [subscription,setSubscription] = useState("");
    const [selectedPlan,setSelectedPlan] = useState({});
    const [user,setUser] = useState({});
    // let userdata = JSON.parse(localStorage.getItem('userdata'));
   // const yourDate = moment(userdata.created);
    // const isInDecember = yourDate.month() <= 3; 
   // const isInDecember = (yourDate.month() <= 3 && (userdata.stripe_customer_id == "" || userdata.stripe_customer_id == null) && userdata.registration_source != "NEXGEN"); 
    // const isInDecember = (yourDate.month() <= 3 && userdata.has_trial_used == 0); 
        
    useEffect(function(){    
    axios
        .post("/webinar/getUserPayments", {}, {
        headers: { "X-Auth": localStorage.getItem("authToken") },
        })
        .then((res) => {
        if (res.data.success) {
            let rec = res.data.data.filter((item)=> !item.subscription_cancelled_at && item.status );
            if(rec.length > 0){
                let parsedData = JSON.parse(rec[0].request);
                 setSubscription(parsedData.product_id);
            }
        } else if (res.data.errors && res.data.errors.length > 0) {
            setSubscription("");
        } else {
            console.error(res.data.msg);
        }
        })
        .catch((err) => {
        console.log("err", err);
        });
    },[])

    const stripePromise = loadStripe(STRIPE_KEY);
    const getPlans = ()=>{
        setShowLoader(true)
		axios.post('/webinar/get-plan-for-users', {}, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
		    setShowLoader(false)
            if(res.data.success){
                    setPlans(res.data.data);
                    setUser(res.data.user);
                    // if(subscription){
                    //     let filteredData = res.data.data.filter((item)=>item.product_id != subscription)
                    //     if(filteredData){
                    //         setSelectedPlan(filteredData[0]);
                    //     }else{
                    //         setSelectedPlan({});
                    //     }
                    // }else{
                    //     let p = res.data.data.find((item)=>item.id == res.data.user.plan_id)
                    //     if(p){
                    //         setSelectedPlan(p);
                    //     }else{
                    //         setSelectedPlan(res.data.data[0]);
                    //     }
                    // }
                    
                }else{
                    setPlans([]);
                    setUser({});
                }
            }).catch((err) => {
                setShowLoader(false)
                console.log("Error while fetching the plans",err)
            })
    }

    useEffect(()=>{
        getPlans();
    },[subscription])
    return (
        <>
        <LoadingOverlay        
                  active={showLoader}
                  // spinner={<BounceLoader />}
                  spinner={<div><CustomImageLoader image={LGspinner} animationType={'pulse'}/><br/></div>}
                  text='Loading your Plans...'
                >
            <Row>
                <Col md={2}></Col>
                <Col md={9}>
                    <br />
                    <br />
                    <div className='text-center'>
                        <h3>Subscription Plan Options</h3>
                        <p>Standard (Monthly or Annual billing)</p>
                    </div>
                    <h2>CHOOSE YOUR PLAN</h2>
                    
                    {/* <ul style={{'list-style' : "none"}}>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Join all webinar events</li>
                        <li> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;Access all on-demand videos </li>
                        <li> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;New live webinar events being scheduled every week </li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Explore inspiring speakers and life-enhancing coaches </li>
                        <li><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Cancel your subscription any time </li>
                    </ul> */}
                    <Row>
                    {   plans.length > 0 && plans.map((item,key)=>{

                            return <> <div className={`col-12  col-md-6`} key={key}>
                            <div className={`card shadow ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`} >
                            <div className="card-body p-4 p-xxl-5">
                                {/* <h2 className="h4 mb-2">{item.plan_name}</h2> */}
                                {/* <div className='amp mt-5'>
                                    <h2 style={{"font-size":"1.45rem"}}> 
                                        Limited Time Offer <br />
                                            <small style={{color : "#000"}}> Sign up now and get 7 days access (no credit card required)</small>
                                    </h2>
                                </div>
                                
                                <hr className="my-4 border-dark-subtle" /> */}
                                <div className="plan-amount-wrapper position-relative mb-4">
                                <div className="plan-amount bsb-animated">
                                    <h4 className="display-3 fw-bold mb-0" style={{"color":"#00e1ba"}}>Standard</h4>
                                    {/* <p className="text-secondary mb-0">USD / {item.time_period === '1' ? this.state.filter_type == "monthly" ? "monthly" : "annually" : this.state.filter_type == "monthly" ? item.time_period+" month" : item.time_period+" annual"}</p> */}
                                    <p className="h4 mb-5">{item.plan_name}</p>
                                </div>
                                </div>
                                {/* <p className="text-secondary mb-0">{item.description}</p> */}
                                <div className="d-grid gap-2 my-4">
                                </div>
                                <ul className="list-group list-group-flush m-0 mt-1">
                                    <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Join all live coaching webinar events</li>
                                    <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;Access all on-demand videos </li>
                                    <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}> <i className="fa fa-check" aria-hidden="true"></i> &nbsp;New live webinar events being scheduled every week </li>
                                    <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Explore inspiring speakers and life-enhancing coaches </li>
                                    <li className={`list-group-item ${key % 2 === 0 ? 'bg-transparent' : 'bg-light'}`}><i className="fa fa-check" aria-hidden="true"></i> &nbsp;Cancel your subscription any time </li>                        
                                </ul>
                                <div className="text-center mt-2">
                                {(subscription && subscription == item.product_id) 
                                    
                                ? 
                                    <span className='btn btn-light' style={{"color":"#fff","backgroundColor":"#00e1ba","border": "3px solid #00e1ba"}} disabled={true}>Current plan</span> 
                                :
                                    <span className='btn btn-light' style={selectedPlan.id == item.id ? {"backgroundColor":"orange","border": "3px solid orange","margin-left": "1rem","color":"#fff"} : {"margin-left": "1rem","border":"3px solid #eee"}}  onClick={()=>{
                                        setSelectedPlan(item);
                                    }}>{selectedPlan.id == item.id ? "Selected" : "Select"}</span>
                                }
                                
                                </div>
                            </div>
                            </div>
                            </div> 
                            </>
                            })
                    }
                    </Row>
                    
                <br />
                <div className='new_card mb-5 text-center' >
                    <div style={{"width":"500px","margin" : "auto"}}>
                    <Elements stripe={stripePromise}>
                        <CheckoutForm totalAmount={selectedPlan?.offer_price} selectedPlan={selectedPlan} setShowLoader = {setShowLoader}/>
                    </Elements>
                    </div>
                </div>

                <p>Upgrade your plan to unlock unlimited access to all on-demand videos and join unlimited live life coaching sessions.</p>
                <p>Want to cancel your subscription ? Subscription plans may be cancelled at any time from the "Payment History" tab on your dashboard. Please see full <a href="/terms" target="_blank">T&C's</a></p>
                
                </Col>
                </Row>
                </LoadingOverlay>
                </>
    )
}
