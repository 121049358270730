import React, { useState,useEffect } from 'react';
import { Container, Row, Col, Card, Pagination ,Button} from 'react-bootstrap';
import axios from "../config/axios";
import moment from "moment";
import { VideoPlayer } from "../components/media";
import TrialActivationModal from '../components/webinars/TrialActivationModal';
import message from "../utils/mesaages"


const VideoRecordings = (props) => {
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const itemsPerPage = 9; // Number of recordings to display per page
    const [activePage, setActivePage] = useState(1);
    const [search, setSearch] = useState("");
    const [category, setCategory] = useState("");
    const [currentRecordings, setCurrentRecordings] = useState([]);
    const [dummyRecordings, setDummyRecordings] = useState([]);
    const [list, setCategories] = useState([]);
    const [isFirst, setIsFirst] = useState(true);
    const [unlockedVideo, setGetUnlockedVideo] = useState([]);
    let date_today = new Date();
    let firstDay = new Date(date_today.getFullYear(), date_today.getMonth(), 1);
    firstDay = firstDay.toLocaleDateString("en-CA", { // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    
    let lastDay = new Date(date_today.getFullYear(), date_today.getMonth() + 1, 0);
    lastDay = lastDay.toLocaleDateString("en-CA", { // you can use undefined as first argument
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    const [start_date, setStartDate] = useState("")
    const [end_date, setEndDate] = useState("")
    // const [start_date, setStartDate] = useState(firstDay)
    // const [end_date, setEndDate] = useState(lastDay)

    const getCategories = ()=>{
        let url = "webinar_categorie";
        if(props.type){
          url = "careermaster_categorie";
        }
        if(localStorage.getItem("ML_TOKEN")){
          url = url+"?for=jobseeker";
        }
        axios.get(`/${url}`, {}, {}).then((res) => {
            if(res.data.success){
                setCategories(res.data.deta);
            }else{
                setCategories([]);
            }
        }).catch((err) => {
            setCategories([]);
        });
    }
    
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        handleReords(dummyRecordings,pageNumber);
       
    };

    const handleReords = (data,pageNumber)=>{
        if(!pageNumber) pageNumber = activePage;  
        let indexOfLastRecording = pageNumber * itemsPerPage;
        let indexOfFirstRecording = indexOfLastRecording - itemsPerPage;
        setCurrentRecordings(data.slice(indexOfFirstRecording, indexOfLastRecording));
    }

    const getRecordings = ()=>{
        axios.post('/webinar/get-recording', { 
            pageNumber: activePage,
            search : search,
            category : category,
            type : props.type,
            start_date : start_date,
            end_date : end_date,
            for : localStorage.getItem("ML_TOKEN") ? 'jobseeker' : 'user'
        }, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
            if(res.data.success){
                setDummyRecordings(res.data.data)
                handleReords(res.data.data,1);
            }else{
                setDummyRecordings({});
                setCurrentRecordings({});
            }
          }).catch((err) => {
            console.log(err)
          })
    }

  useEffect(()=>{
    getRecordings();
  },[search,category,props.type,end_date,start_date]);

  useEffect(()=>{
    setCategory("");
    getCategories();
    getUnlockVideo();
  },[props.type]);

  const getUnlockVideo = (id) => {
    axios
      .get('/webinar/get-unlock-recording', {
        params: { video_id: id }, // Send video_id as a query parameter
        headers: { "x-auth": localStorage.getItem("authToken") },
      })
      .then((res) => {
        if (res.data.success) {
          // Extract only the recording_id from the response data and set it
          const recordingIds = res.data.data.map(item => item.recording_id);
          setGetUnlockedVideo(recordingIds); // Set the array of recording_ids
        } else {
          message.error(res.data.message); // Show error message from response
        }
      })
      .catch((err) => {
        console.error('Error fetching unlocked video:', err);
        message.error('Failed to fetch unlocked video. Please try again later.');
      });
  };

  

  const unlockVideo = (id)=>{
    axios.post('/webinar/unlock-recording', { 
      video_id: id
    }, { headers: { "x-auth": localStorage.getItem("authToken")}, }).then((res) => {
      if(res.data.success){
        message.success(res.data.message);   
        getUnlockVideo();       
      }else{
        message.config({
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          style: {
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            zIndex: 1000,
          }
        });
        message.error({
          content: res.data.message,
          duration: 10, // Display the message for 30 seconds
        });
        if(res.data.is_free){
          setShowPaymentModal(true);
        }
      }
    }).catch((err) => {
      console.log(err)
    })

  }

  return (
    <Container>
      { showPaymentModal && <TrialActivationModal  close = {()=>{setShowPaymentModal(false)}} show={Math.ceil()} /> }
         <Row style={{"marginTop":"2rem"}}>

         <Col  md={3}>
            <label htmlFor="">Start Date</label>
            <input type="date"  onChange={(e)=>{
              setStartDate(e.target.value)
            }}  defaultValue={start_date}  className='form-control'/>
          </Col>
          <Col  md={3}>
            <label htmlFor="">End Date</label>
            <input type="date" onChange={(e)=>{
              setEndDate(e.target.value)
            }} defaultValue={end_date}  className='form-control'/>
          </Col>

      <Col  md={3}>
        <label>Search By Title</label>
        <input type="text" placeholder="Enter at least 2 characters to search" className="form-control" onChange={(e)=>{
            if(e.target.value.length > 2 || e.target.value.length == 0){
                setSearch(e.target.value);
            }
        }}/>
        </Col>

      <Col  md={3}>
        <label>Select Category</label>
        <select className="form-control"  onChange={(e)=>{
            setCategory(e.target.value);
        }}>
            <option value="" selected>All</option>
            {list.length >= 1 &&
              list.map((item, i) => (
                <option key={i} value={item.value} selected={category === item.value}>
                  {item.label}
                </option>
              ))}
        </select>
        </Col>
        </Row>
      <Row style={{"marginTop":"2rem"}}>

     

        {currentRecordings.length >= 1 && currentRecordings?.map((recording,i) => (
          <Col key={i} md={4} style={{"marginTop":"2rem"}}>
            <Card className="bg-dark text-white">
                {props.type 
                ? 
                 <VideoPlayer
                    id={recording.i}
                    src={recording.link}
                    poster={
                        "https://markipo.com/Markipo_Reportages_Cyclisme_13_10/res/video.poster.jpg"
                    }
                    /> 
                : 
                <Card.Img  style={{"height" : "180px"}} src={recording.image} alt="Card image" />
                }
                
                
                    <Card.Body>
                        <Card.Title>{recording.title.length >= 25 ? recording.title.substring(0, 24) +"..." : recording.title }</Card.Title>
                        <Card.Text>{recording.categrory_name}</Card.Text>
                        <Card.Text>{recording.description.length >= 25 ? recording.description.substring(0, 24) +"..." : recording.description }
                        { !props.type && <a style={{"color":"#00e1ba"}} href={`/webinars/webinars-details/${recording?.coach_uid}/${recording?.id}`} className="">&nbsp;&nbsp;[...]</a> }
                        </Card.Text>
                        {props.type ? "" : 
                        (unlockedVideo.includes(recording.video_id)
                        ? <a href={recording.link} target="_blank" rel="noopener noreferrer" className="btn btn-primary">Watch Now</a>
                        : <a target="_blank" onClick={() => {unlockVideo(recording.video_id)}} className="btn btn-primary" rel="noopener noreferrer">Unlock</a>)
                          
                          // (props.isExpired && +i > 1 && !localStorage.getItem("ML_TOKEN")) ? 
                          // <a href="javascript:void(0)" onClick={()=>{ setShowPaymentModal(true)}} className="btn btn-primary">Watch Now</a>
                          // : 
                          // <a href={recording.link} target="_blank" className="btn btn-primary">Watch Now</a>
                          }
                    </Card.Body>
                <Card.Footer className="text-muted">{ moment(recording.created_at).format("YYYY-MM-DD")}</Card.Footer>
                </Card>
          </Col>
        ))}
      </Row>
      <Pagination style={{"marginTop":"2rem"}}>
        {Array.from({ length: Math.ceil(dummyRecordings?.length / itemsPerPage) }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={index + 1 === activePage}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
};

export default VideoRecordings;
